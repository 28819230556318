@import 'variables';

html, body {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
}

body {
  background-color: $pearl;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  font-family: $body-font;
  width: 100%;

  > section {
    max-width: 1000px;
    min-height: 75vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 768px) {
      min-height: 100vh;
    }
  }

  .content-container {
    padding: 1rem;
    @media (min-width: 768px) {
      padding: 2rem;
    }
  }
}

button {
  font-family: $body-font;
}

h1 {
  font-family: $body-font;
  font-size: 2rem;
  font-weight: $font-extrabold;
  text-transform:uppercase;

  @media (min-width: 375px) {
    font-size: 2.25rem;
  }
}

h2 {
  font-family: $body-font;
  font-size: 1.75rem;
  font-weight: $font-extrabold;
  letter-spacing: -0.05rem;
  text-transform: uppercase;
  margin: 1rem 0;

  @media (min-width: 400px) {
    font-size: 2rem;
  }
}

h3 {
  font-weight: $font-semibold;
}

p {
  font-size: 1.1rem;
  font-weight: $font-normal;
  letter-spacing: -.015rem;
  line-height: 1.6;
}

a {
  border-bottom: 2.5px solid $teal;
  color: $black;
  font-weight: 600;
  text-decoration: none;
  transition: all $transform-speed ease;

  &:hover {
    background-color: $teal;
    border: 0;
    box-shadow: 0 0 1.25px 1.25px $pearl inset;
    padding: 4px 0;
  }
}

pre {
  display: inline;
  font-size: 1rem;
  margin-left: 6px;
}

#root {
  @media screen and (min-width: 768px) {
    
    display: grid;
    grid-template-columns: minmax(30%, 150px) auto;
    min-height: 100vh;
  }
}
