@import '../../scss/variables.scss';

footer {
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0 0 0;
  padding: 4.25rem 2rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
      24deg,
      lighten($teal-dark, 40%) 0%,
      lighten($teal-dark, 75%) 20%,
      lighten($teal-dark, 90%) 50%,
      lighten($teal-dark, 70%) 70%,
      lighten($teal-dark, 90%) 100%
  );

  @media screen and (min-width: 768px){  
    margin: 3rem 0 0;
    padding: 0 2rem;
  }

  &::before { 
    position: absolute;
    content: "";
    width: 100%;
    top: 0;
    height: 15svh;
    background: linear-gradient(180deg, rgba($pearl,1) 0%, rgba($pearl,0) 100%);
    display: block;
    overflow-x: hidden;
    margin: 0 -2rem;
    z-index: 4;

    @media screen and (min-width: 768px){
        height: 15vh;
    }
}

  &::after {
    position: absolute;
    content: "";
    width: 40svh;
    height: 40svh;
    background: #c49fe7;
    left: 45%;
    bottom: 0;
    transform: translateX(100%) translateY(40%);
    border-radius: 100%;
    z-index: 3;
    filter: blur(60px);
    overflow-x: hidden;
  }

  @media screen and (min-width: 768px) {
    padding-top: 15vh;
    padding-bottom: 15vh;
  }

  p {
    margin: 0 0 1rem 0;
    font-size: 0.8rem;
  }

  a {
    border: 0;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    border: 0;
    fill: $black;
    height: 1.75rem;
    width: 1.75rem;

    &:hover {
      fill: $teal-dark;
    }
  }
}