@use 'sass:color';
@import '../../scss/variables.scss';

#Title {
    max-width: unset;
    
    &::after {
        position: absolute;
        content: "";
        width: -webkit-fill-available;
        bottom: 0;
        height: 10svh;
        background: linear-gradient(0deg, rgba($pearl,1) 0%, rgba($pearl,0) 100%);
        display: block;
        overflow-x: hidden;

        @media screen and (min-width: 768px){
            height: 10vh;
        }
    }

    .content-container {
        position: absolute;
        top: 0;
        height: 100svh;
        z-index: 9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 0;

        @media screen and (min-width: 768px){
            height: 100vh;
        }

        h1 {
            font-size: 3rem;
            line-height: 0.8;
            font-weight: $font-extrabold;
            text-transform: uppercase;
            margin: 0;
            padding: 0;

            @media screen and (min-width: 425px) {
                font-size: 4.5rem;
            }
        }

        ul {
            list-style-type: none;
            padding: 0;

            li {
                display: block;
                text-transform: uppercase;
                font-weight: $font-extrabold;
                margin-right: 1rem;
                padding-right: 1rem;

                @media screen and (min-width: 768px) {
                    border-right: 1.5px solid black;
                    display: inline-block;

                    &:last-child {
                        margin-right: 0;
                        padding-right: 0;
                        border-right: 0;
                    }
                }
            }
        }
    }

    .backdrop {
        position: relative;
        height: 100svh;
        width: 100%;
        margin: auto;
        overflow: hidden;
        background: linear-gradient(
            -75deg,
            lighten($teal-dark, 95%) 0%,
            lighten($teal-dark, 80%) 10%,
            lighten($teal-dark, 65%) 25%,
            lighten($teal-dark, 75%) 45%,
            lighten($teal-dark, 85%) 60%,
            lighten($teal-dark, 70%) 75%,
            lighten($teal-dark, 55%) 85%,
            lighten($teal-dark, 40%) 100%
        );

        @media screen and (min-width: 768px){
            height: 100vh;
        }
    }

    .backdrop::before,
    .backdrop::after,
    .backdrop > .backdrop-blobs,
    .backdrop > .backdrop-blobs::before,
    .backdrop > .backdrop-blobs::after {
        filter: blur(75px);
    }

    .backdrop::before,
    .backdrop::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100svh;
        border-radius: 100%;

        @media screen and (min-width: 768px){
            height: 100vh;
        }
    }

    .backdrop::before {
        background: #eac8d8;
        top: -50%;
        left: -50%;
    }

    .backdrop::after {
        background: #c49fe7;
        right: -70%;
        bottom: 40%;
        transform: translateY(25%);
    }
}
