@import '../../scss/variables.scss';

.navbar {
  background-color: $black;
  color: $color-light;
  font-family: $body-font;
  position: fixed;
  height: 60px;
  width: 100%;
  margin-top: 0;
  top: 0;
  z-index: 10;
  display: block;

  @media screen and (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: end;
    width: $navbar-width;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    height: inherit;
    justify-content: flex-end;

    @media screen and (min-width: 768px) {
      justify-content: flex-end;
    }

    header {
      width: 85%;

      h1 {
        display: flex;
        justify-content: flex-start;
        color: $color-light;
        font-size: 2rem;
        margin: 0;

        @media screen and (min-width: 768px) {
          filter: unset;
          justify-content: flex-end;
          line-height: 0.9;
          text-align: right;
          width: unset;

          &:hover {
            filter: drop-shadow(4px 4px 0px $teal);
          }
        }

        @media screen and (min-width: 900px) {
          font-size: 2.5rem;
        }

        @media screen and (min-width: 1024px) {
          font-size: 2.9rem;
        }
      }
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 15%;
      margin-right: 1rem;

      @media screen and (min-width: 768px) {
        display: none;
      margin-right: unset;
      }
      
      svg {
        stroke: $color-light;
        scale: 3;

        &:hover {
          stroke: lighten($teal, 20%);
        }
      }
    }
  }

  &__menu-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .menu {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: block;
      flex-direction: column;
      overflow: hidden;
      max-height: 0;
      width: 100%;
      pointer-events: none;
      transition: max-height $transform-speed ease;

      @media screen and (min-width: 768px) {
        display: block;
        max-height: unset;
        text-align: right;
        pointer-events: auto;
      }

      .socials-container {
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
        }
      }

      &.open {
        background-color: $black;
        max-height: 110px;
        pointer-events: auto;
      }

      li {
        background-color: $black;
        font-weight: 500;
        margin: 0;
        padding: 0.5rem 0.5rem;
        width: 100%;

        @media screen and (min-width: 768px) {
          font-size: 1.2rem;
          line-height: 1.5;
          margin: 0 0 1.5rem;
          padding: 0.5rem 0;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          background-color: unset;
          border: unset;
          box-shadow: unset;
          color: $color-light;
          font-weight: 600;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;

          @media screen and (min-width: 768px) {
            padding-right: 1rem;
          }

          &:hover {
            color: lighten($teal, 20%);
          }
        }

        &.socials {
          display: flex;
          justify-content: flex-end;
          bottom: 1rem;

          a {
            margin-left: 1em;

            &:hover {
              svg {
                fill: lighten($teal, 20%);
              }
            }
          }

          svg {
            position: relative;
            right: 3px;
            top: 3px;
            transition: fill $transform-speed ease;
            scale: 1.33;
          }
        }
      }
    }
  }
}
