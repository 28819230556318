@use 'sass:color';

$black: #171419;
$white: #fff;

$teal-dark: #0F5358;

$teal: color.scale($teal-dark, $lightness: 50%, $saturation: -20%);
$pearl: color.scale($teal-dark, $lightness: 99%, $saturation: -95%);
$orange: #F56720;

$color-light: $pearl;
$color-mid: $orange;
$color-dark: $teal;

$serif: 'Garamond', serif;
$sans-serif: 'Rubik', sans-serif;

$body-font: $sans-serif;
$heading-font: 'Rubik Mono One';

$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 900;

$link-color: $teal;

$transform-speed: 0.25s;

$navbar-width: 250px;
$navbar-padding-horizontal: 1rem;