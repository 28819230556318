@use 'sass:color';
@import '../../scss/variables.scss';

#About {
    p.intro {
        font-size: 1.5rem;
        font-weight: $font-bold;
        letter-spacing: -.015rem;
        line-height: 1.5;
        margin-top: 0;
    }

    .headshot-container {
        background: radial-gradient(
            circle at left bottom,
            #c49fe7 0%,
            lighten($teal-dark, 50%) 50%,
            lighten($teal-dark, 100%) 72%,
            lighten($teal-dark, 100%) 100%,
        );
        float: right;
        line-height: 0;
        margin: 0 0 0.2rem 1rem;
        width: 150px;
        box-shadow: 0 0 4.5px 4.5px $pearl inset;
        position: relative;
        border-radius: 75px;
    }

    img.headshot {
        filter: sepia(1) saturate(3%);
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        transform: scale(0.95);
        border-radius: 75px;
    }
}
