@use 'sass:color';
@import '../../scss/variables.scss';

.accordion-item {
  border-bottom: 1px solid $black;
  margin-bottom: 0;
  overflow: hidden;
  transition: all $transform-speed ease;

  &__heading {
    .item-toggle {
      border: none;
      background-color: transparent;
      color: $black;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      position: relative;
      width: 100%;

      &:hover {
        color: color.adjust($teal, $lightness: -25%);
      }

      &:active {
        color: $black;
      }

      &__text {
        text-align: left;
        width: calc(100% - 2rem);

        h3 {
          margin: 0;
          font-size: 1.25rem;
        }

        span {
          color: $black;
          display: block;
          font-size: 0.95rem;
          font-weight: 400;
          line-height: 1.25;
        }
      }

      svg {
        stroke: $black;
        scale: 2;
        position: absolute;
        right: 0.33rem;
        top: 1.15rem;
        transition: transform $transform-speed ease-in-out;

        .accordion-item.open & {
          transform: rotate(45deg);
        }

        @media  screen and (min-width: 900px) {
          top: 1.5rem;
        }
      }
    }
  }

  &__content {
    display: block;
    max-height: 0;
    transition: max-height $transform-speed ease-out;
    overflow: hidden;

    &.open {
      max-height: 400px;
      transition: max-height $transform-speed ease-in;

      &.cardmode {
        max-height: 1400px;
      }
    }

    &.cardmode {
      @media screen and (min-width: 900px) {
        gap: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    ul {
      font-size: 0.9rem;
      padding: 0 0 0 1rem;
      margin: 0;

      li {
        margin: 0 0 0.5rem;

        &:last-child {
          margin: 0 0 1.5rem;
        }
      }
    }

    .accordion-card {
      background-color: color.adjust($color-light, $lightness: -7.5%, $saturation: -15%);
      border: 0;
      color: $black;
      display: grid;
      grid-gap: 8px 8px;
      grid-template-columns: 75px 1fr;
      grid-template-rows: max-content 1fr; 
      font-weight: normal;
      padding: 0.5rem;
      margin: 0 0 16px;
      min-height: 75px;
      text-decoration: none;
      transition: all $transform-speed/2 ease;

      &:hover {
        background-color: $teal;
      }

      @media screen and (min-width: 900px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content); 
        height: fit-content;
        padding-bottom: 2rem;
      }
 
      &__image {
        grid-area: 1 / 1 / 3 / 2;
        margin-bottom: 0.25rem;
        max-width: 100%;
        width: 100%;

        @media screen and (min-width: 900px) {
          grid-area: 1 / 1 / 1 / 3;
        }
      }

      &__title {
        font-size: 1rem;
        line-height: 1.15;
        grid-area: 1 / 2 / 2 / 3;
        margin: 0;

        @media screen and (min-width: 900px) {
          grid-area: 2 / 1 / 2 / 3;
        }
      }

      &__meta {
        grid-area: 2 / 2 / 3 / 3;
        font-size: 0.8rem;
        margin: 0;

        @media screen and (min-width: 900px) {
          grid-area: 3 / 1 / 3 / 3;
        }
      }
    }
  }
}