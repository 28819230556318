@use 'sass:color';
@import '../../scss/variables.scss';

#root {
    @media screen and (min-width: 768px) {
        display: grid; 
        grid-template-columns: $navbar-width auto; 
        grid-template-rows: max-content auto; 
        gap: 0px 0px; 

        .navbar { grid-area: 1 / 1 / 3 / 2; }
        main { grid-area: 1 / 2 / 2 / 3; }
        footer { grid-area: 2 / 2 / 3 / 3; }
    }
}